// export const baseUrl = "http://localhost:8080";
// export const domainUrl = "http://localhost:3000";
// export const baseUrl = "http://34.231.241.106/api";
// export const domainUrl = "http://34.231.241.106";

// export const baseUrl = "http://52.23.152.125/api";
// export const baseUrl = "https://qviple.com";
export const baseUrl = "https://apis.qviple.com";

// export const baseUrl = "http://54.237.4.164/api";

export const postMethod = "POST";
export const patchMethod = "PATCH";
export const putMethod = "PUT";
export const deleteMethod = "DELETE";

// var token = localStorage.getItem("token");
export const session = () => {
  var token = localStorage.getItem("token");
  setTimeout(() => {
    if (token) {
      return "";
    } else {
      token = localStorage.getItem("token");
    }
  }, [100]);
  return token;
};

// export const imageShowUrl1 =
//   "https://qviple-productionelopment.s3.ap-south-1.amazonaws.com";
// export const imageShowUrl =
//   "https://qviple-productionelopment.s3.ap-south-1.amazonaws.com";

// export const imageShowUrl1 = "http://34.231.241.106/api/api/v1/all-images";
// export const imageShowUrl = "http://34.231.241.106/api/api/v1/all-images";

// export const imageShowUrl1 =
//   "https://qviple-productionelopment.s3.ap-south-1.amazonaws.com";
// export const imageShowUrl =
//   "https://qviple-productionelopment.s3.ap-south-1.amazonaws.com";
// export const paytmActionUrl = "https://securegw-stage.paytm.in/order/process";
// export const pdfShowUrlNext =
//   "https://qviple-productionelopment.s3.ap-south-1.amazonaws.com";

// export const imageShowUrl1 = "https://qviple.com/api/v1/all-images";
// export const imageShowUrl = "https://duoxxvrxc30wv.cloudfront.net";
// export const paytmActionUrl = "https://securegw.paytm.in/order/process";
// export const pdfShowUrlNext = "https://qviple-production.s3.ap-south-1.amazonaws.com";
// export const domainUrl = "https://dashboard.qviple.com";

export const imageShowUrl1 =
  "https://qviple-production.s3.ap-south-1.amazonaws.com";
export const imageShowUrl = "https://duoxxvrxc30wv.cloudfront.net";
export const paytmActionUrl = "https://securegw.paytm.in/order/process";
export const pdfShowUrlNext =
  "https://qviple-production.s3.ap-south-1.amazonaws.com";
export const domainUrl = "https://dashboard.qviple.com";

// export const imageShowUrl1 = "http://localhost:8080/api/v1/all-images";
// export const imageShowUrl = "http://localhost:8080/api/v1/all-images";
export const imageShowUrl2 = "https://duoxxvrxc30wv.cloudfront.net/web-client";

export const fileShowUrl = "https://duoxxvrxc30wv.cloudfront.net";

export const customAvatarUrl = "https://duoxxvrxc30wv.cloudfront.net/3d-avatar";
